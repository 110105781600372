export function parseDateOrDefault(dateString, def) {
  if (dateString === null || dateString === "") {
    return def;
  }

  return new Date(dateString).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  })
}

/**
 * Capitalizes a string. Returns back input if invalid string to capitalize.
 * @param {string} stringToCapitalize The string to capitalize
 * @returns {string} A capitalized string, or the provided string if capitalization not possible
 */
export function capitalized(stringToCapitalize) {
  if (stringToCapitalize == null) return stringToCapitalize;
  if (stringToCapitalize.length === 0) return stringToCapitalize;

  if (stringToCapitalize.length === 1) return stringToCapitalize[0].toUpperCase();
  return stringToCapitalize[0].toUpperCase() + stringToCapitalize.slice(1);
}

/**
 * Returns a resource string for a language based on the input, which can
 * be used to get the full language name.
 * Currently only supports English and Norwegian language codes.
 *
 * @example
 * const languageResource = getLanguageResourceStringByCode('no');  // returns 'language.norwegian'
 * console.log(this.$t(languageResource);  // outputs e.g. "Norwegian"
 *
 * @param {string} languageCode A language code, e.g. 'en' for english
 * @returns {string} A string of the language for the resources files, "language.norwegian" by default
 */
export function getLanguageResourceId(languageCode) {
  return languageCode === 'en' ? "language.english" : "language.norwegian";
}