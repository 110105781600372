import api, {http, fileDownload} from "@/services/api";

export const isEmailRegisteredInGroup = async (groupId, email) => {
  const result = await api.post("/courseevent/isEmailRegistered", {
    GroupId: groupId,
    Email: email
  });
  return result.data;
}

export const getInstructors = async () => {
  const result = await api.get(`/courseevent/instructors`);
  return result.data;
};

export const postCourseEvent = async (payload) => {
    return await api.post(`/courseevent`, payload);
};

export const putCourseEvent = async (payload) => {
    return await api.put(`/courseevent/${payload.id == null ? "" : payload.id}`, payload);
};

export const getCourseEvents = async () => {
  const result = await api.get(`/courseevent/courseevents`);
  return result.data;
};

export const getInactiveCourseEvents = async () => {
  const result = await api.post(`/courseevent/filteredevents`, {
    Title: null,
    Active: false,
  });
  return result.data;
};

export const getCourseEvent = async (id) => {
    const result = await api.get(`/courseevent/${id}`);
    return result.data;
};

export const createParticipant = async (payload) => {
    const result = await api.post(`/courseevent/participant`, payload);
    return result.data;
};
  
export const saveSession = async (payload) => {
    const result = await api.put(`/courseevent/session`, payload);
    return result;
};

export const updateSessionStatus = async (payload) => {
  return await api.put(`/courseevent/sessionStatus`, payload);
};

export const saveParticipants = async (payload) => {
    return await api.put(`/courseevent/participants`, payload);
};

export const downloadCertificatePreview = async (courseEventId) => {
    const endpoint = `/api/courseevent/certificatePreview/${courseEventId}`;
    const filename = "Kurssertifikat.pdf";
    await fileDownload(endpoint, filename);
};

/** Registration banner calls */
export const uploadRegistrationBanner = async (payload, courseEventId) => {
  const fd = new FormData();
  fd.append('image', payload);
  return await http.request({
    method: 'post',
    url: '/images/background/courseevent/' + courseEventId,
    data: fd,
    headers: { 'content-type': 'multipart/form-data' }
  });
}

export const deleteRegistrationBanner = async (courseEventId) => {
  return await http.request({
    method: "delete",
    url: "/images/background/courseevent/" + courseEventId
  });
}

export const registrationBannerExists = async (courseEventId) => {
  const result = await http.request({
    method: "get",
    url: "/images/background/courseevent/exists/" + courseEventId
  });
  return result.data;
}
